.footer {
    background-color: #F2F2F2;
    font-family: 'Altice-Regular';
    width: 100%;
    z-index: -2;
}

.footerDiv {
    background-color: #F2F2F2;
    padding: 30px;
    margin-top: 4%;
    bottom: 0%;
    position: relative;
    z-index: -2;
}

a {
    color: black;
}

.backgroundImg {
    width: 500;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
}

@media screen and (max-width: 767px) {
    .backgroundImg {
        bottom: 96px;
        width: 380px;
    }
}

@media screen and (min-width: 768px) {
    .backgroundImg {
        bottom: 84px;
        width: 642px;
    }
}
@media screen and (min-width: 992px) {
    .backgroundImg {
        bottom: 75px;
        width: 886px;
    }
}
@media screen and (min-width: 1200px) {
    .backgroundImg {
        bottom: 71px ;
        width: 1042px;
    }
}
@media screen and (min-width: 1536px) {
    .backgroundImg {
        bottom: 60px ;
        width: 1300px;
    }
}