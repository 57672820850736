@font-face {
  font-family: 'Montserrat-Regular';
  /* font-style: normal;
  font-weight: 400;
  font-display: swap; */
 // src: url('~assets/fonts/Montserrat-Regular.ttf') format('truetype');
 src: url('../../../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  /* font-style: bold;
  font-weight: 700;
  font-display: swap; */
  src: url('../../../fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-light';
  /* font-weight: 300;
  font-display: swap; */
  src: url('../../../fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  /* font-weight: 300;
  font-display: swap; */
  src: url('../../../fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  /* font-weight: 300;
  font-display: swap; */
  src: url('../../../fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Altice-Bold';
  /* font-weight: 300;
  font-display: swap; */
  src: url('../../../fonts/Altice-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Altice-Regular';
  /* font-weight: 300;
  font-display: swap; */
  src: url('../../../fonts/Altice-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Altice';
  font-weight: 400;
  src: url('../../../fonts/Altice-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Altice';
  font-weight: 700;
  src: url('../../../fonts/Altice-Bold.otf') format('truetype');
}


$font-families: ('regular': $app-regular-font, 'semi-bold': $app-semi-bold-font);

@each $name, $value in $font-families {
  .live-ff-#{$name} {
    font-family: $value !important;
  }
}
