
.no-scrollbar--arrows-bottom{

    &.react-horizontal-scrolling-menu--wrapper {
      flex-wrap: wrap;
      justify-content: end;
    }
  
    >.react-horizontal-scrolling-menu--scroll-container {
      padding: 15px 15px 15px 4px;
      flex-basis: 100%;
      flex-shrink: 0;  
      /* Change position of container so arrows will be at top/bottom */  
      order: -1;
  
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      
      /* chrome and chromium based */
      &::-webkit-scrollbar {
        display: none;
      }
    }
}