.miniSelect {
  width: 50px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: none ;
  }
  .customSelect
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
.miniSelectWithLabel {
  max-width: 300px;
}
