.filter-button {
  position: absolute;
  right: 24px;

  &-noResults {
    flex-direction: row;
    top: 28px;
  }
  &-results {
    top: 36px;
  }
}

.list {
  &-title {
    padding: 28px 20px;

    &-flex {
      flex-direction: row;
      align-items: center;
    }
    &-block {
      display: block !important;
    }
  }
  &-detail {
    padding: 0 20px;
    height: 87vh;
    overflow: auto;

    &__title {
      padding: 28px 20px 0 20px;
    }
    &__subTitle {
      padding: 0 20px 28px 20px;
    }
    &__card {
      padding-bottom: 16px !important;
    }
    &__chart {
      min-height: 300px;
    }
  }
}
