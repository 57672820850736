.loginLogo {
    width: 40%;
    padding-left: 5%;
}

.loginCard {
    border: none;
    width: 74%;
    margin-left: 15%;
    margin-top: 15%;
    box-shadow: none !important;
    background-color: transparent !important;
}

.uploadProfileImg {
    border: 1px dashed #333333;
    border-radius: 20px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.logo {
    width: 7%;
}

.clickNavigateLogin {
    font-family: 'Altice-Bold' !important;
    cursor: pointer;
}

.pagesDiv {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.typographyHeader_ {
    color: black
}

.typographyHeader {
    margin-left: 2%;
    font-weight: bold !important;
    padding: 3%;
}

.loginImage {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.loginImageDiv {
    display: flex;
    justify-content: flex-end;
    overflow: hidden
}