


.fullWidth {
  width: 100%;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiTextField-root {
    margin-bottom: 0px;
  }
}
