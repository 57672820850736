// Google Material Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "utils";
@import "globals";
@import "components";

// flag icons
@import "../../../node_modules/flag-icons/sass/flag-icons.scss";
.fi-rounded {
  border-radius: 50%;
  height: 1.3em;
  background-size: cover;
  margin-right: 5px;
  margin-left: 2px;
  &.fis {
    width: 1.6em;
    height: 1.6em;
  }
}
.fi-sise-xs {
  height: 1em;
  &.fis {
    width: 1em;
    height: 1em;
  }
}
.fi-sise-sm {
  height: 1.3em;
  &.fis {
    width: 1.3em;
    height: 1.3em;
  }
}
.fi-sise-md {
  height: 1.6em;
  &.fis {
    width: 1.6em;
    height: 1.6em;
  }
}
.fi-sise-lg {
  height: 1.9em;
  &.fis {
    width: 1.9em;
    height: 1.9em;
  }
}

// drop zone
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  // &:focus,
  &:hover{
    border-color: #2196f3;
  }

  &.disabled{
    opacity: 0.6;  
  }
}

::-webkit-scrollbar {
   /* Foreground, Background */
   scrollbar-color: $scrollbar-foreground $scrollbar-background;
  width: 5px; /* Mostly for vertical scrollbars */
  height: 5px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb { /* Foreground */
  background: $scrollbar-foreground;
  border-radius: 10px;
}
::-webkit-scrollbar-track { /* Background */
  background: $scrollbar-background;
  border-radius: 10px;
}

a {
  text-decoration: none;
}

html,
body,
#root {
  height: 100vh;
  margin: 0;
}

#root {
  display: flex;
}

input::-ms-reveal, 
input::-ms-clear {
  display: none;
}

.live-required abbr {
  border-bottom: none;
  display: inline-block;
  outline: none;
  position: relative;
  text-decoration: none;
  // font-size: $app-font-size-md;

  &::after {
    content: "\00a0*\00a0";
    color: $required-color;
  }
}

.css-s6t4rt-MuiSvgIcon-root {
  padding: 0 !important;
}

body {
  background-color: #f5f5f5;
}

*,*::before,*::after{
  box-sizing:inherit;
}