$primary: #51bfbc;
$secondary: #C2CE51; // sidebar, ilustrações, etc

:root {
    --background: #fff;
    --background-secondary: #fff;
    --text-primary: black;
    --text-secondary: royalblue;
    --accent: green;
    --border: 1px solid #ddd;
    --icon-color:#333;
  }
  
  [data-theme='dark'] {
    --background: #303030;
    --background-secondary: #3d3d3d;
    --text-primary: white;
    --text-secondary: grey;
    --accent: darkred;
    --border: none;
    --icon-color:#c2c0c0;
  }